<template>
  <div class="about-page">
    <kinesis-container event="scroll" class="container">
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-first">
          <img src="../assets/images/kinesis-first.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-second">
          <img src="../assets/images/kinesis-second.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-third">
          <img src="../assets/images/kinesis-third.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-fourth">
          <img src="../assets/images/kinesis-fourth.svg" />
        </div>
      </kinesis-element>
      <kinesis-element :cycle="1" :strength="10">
        <div class="kinesis kinesis-five">
          <img src="../assets/images/kinesis-five.svg" />
        </div>
      </kinesis-element>
      <div class="about-page__wrapper about-page__wrapper-partners" v-if="news">
        <h1 class="text-rothek font-104 mb-60">{{ news.title }}</h1>
        <div class="text-rothek font-36 mb-30" v-html="news.text"></div>
      </div>
      <feedback fill="orange" />
    </kinesis-container>
  </div>
</template>

<script>
import Feedback from "../components/Feedback.vue";
import axios from "axios";
export default {
  name: "PartnersPage",
  components: {Feedback},
  data() {
    return {
      news: null
    }
  },
  async mounted() {
    this.news = (await axios.get('documents/2')).data
  }
}
</script>

<style scoped>

</style>